import { EventApi, EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Modification } from 'store/modules/modifications';

// https://egghead.io/blog/using-branded-types-in-typescript
export type Brand<K, T> = K & { __brand: T };

export interface LocationState {
  path?: string;
  logout?: boolean;
}

export enum Disciplines {
  RadOnc = 'radonc',
  MedOnc = 'medonc',
}

// Status options for jobs running on a compute node on the backend.
export enum StatusChoices {
  NotStarted = 0,
  Running = 1,
  Finished = 2,
  Error = -1,
}

export enum Inertia {
  STRICT = 4,
  SEMISTRICT = 3,
  CONSTRAINED = 2,
  SEMICONSTRAINED = 1,
  FREE = 0,
}

export enum ConstraintType {
  Preference = 'preference',
  Requirement = 'requirement',
}

export enum SchedulingWizardStage {
  ListView = 0,
  CarePlanCustomization = 1,
  MachineSelection = 2,
  Constraints = 3,
}

export enum SchedulingJobStatus {
  NotStarted = 0,
  Running = 1,
  InReview = 2,
  Finalized = 3,
  Rejected = 4,
  Error = -1,
}

export enum SchedulingCalendarViews {
  ByPatient = 'PatientCentric',
  ByMachine = 'MachineCentric',
  ByDayPlanning = 'DayCentricPlanning',
  ByDayTreatment = 'DayCentricTreatment',
  MovedAppointments = 'AllMovedAppointments',
}

// Different kinds of appointments
export enum AppointmentKind {
  // PatientAppointmentKinds
  treatment = 'treatment',
  planning = 'planning',
  pharmacy = 'pharmacy',
  service = 'service',

  lab = 'lab',

  // Staff based appointments
  consult = 'consult',
  staff = 'staff',

  // OrgAppointmentKinds
  QA = 'QA',
  break = 'break',
  lunch = 'lunch',
  block = 'block',
  meeting = 'meeting',
  memo = 'memo',

  reserved = 'reserved',
  emergency = 'emergency',

  other = 'other',
  unknown = 'unknown',

  // It would be great if we could merge enums, Microsoft plz
}

// These are all the different status a block could have (which will determine its color)
export enum BlockStatus {
  available = 'available', // A new patient can start here
  unavailable = 'unavailable', // A new patient cannot start here even though nobody is scheduled
  scheduled = 'scheduled', // A patient is scheduled on this block
  break = 'break', // Staff break
  lunch = 'lunch', // Staff lunch break
  block = 'block', // Staff blocked off this block
  QA = 'QA', // Staff QA
}

export enum LunchTimes {
  Early = '11:00:00',
  Noon = '12:00:00',
  Late = '13:00:00',
}

export enum StaffMemberKind {
  physician = 'physician',
  physicist = 'physicist',
  pharmacist = 'pharmacist',
  nurse = 'nurse',
  therapist = 'therapist',
  clerk = 'clerk',
}

// All the kinds of schedule rules specifiable for a time period in the calendar view.
export enum ScheduleRuleKind {
  Available = 'available',
  Unavailable = 'unavailable',
  Avoid = 'avoid',
  Unknown = 'unknown',
}

export enum ActivityModality {
  TX = 'TX',
  CT = 'CT',
  MRI = 'MRI',
  PET = 'PET',
  MOLD = 'ML',
  CONSULT = 'CS',
  VISIT = 'VS',
  OTHER = 'NA', // for org appointments
}

export enum WeekDays {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}

export interface Holiday {
  date: string;
  name: string;
}

export interface MetaDataTag {
  name: string; // Internal storage string
  icon: string; // BlueprintJS icon name
  prettyName: string; // Name to display on the app
  color?: string; // Optional color
  category?: string;
  parent: string;
  hidden: boolean;
}

export interface HistogramThresholds {
  warning: number;
  danger: number;
}

export interface Team {
  id: string;
  name: string;
  metaTags: MetaDataTag[];
  discipline: Disciplines;
  institution: Institution['id'];
  defaultParameters: {
    metaTags: MetaDataTag[];
    weeklyView: {
      // tags' category
      firstColumn: string;
      secondColumn: string;
      thirdColumn: string;
    };
    dashboardThresholds: {
      maxStartsPerSlot: {
        // Time format is HH:mm
        [time: string]: HistogramThresholds;
      };

      maxByDuration: {
        [duration: number]: HistogramThresholds;
      };

      maxHeatmap: {
        [timeSlots: string]: {
          [duration: number]: HistogramThresholds;
        };
      };
    };

    moP2?: {
      /** If a moved appointment's time delta is within this number (backwards, forwards) in units of time blocks, we apply short_move and instead */
      shortMovedLimits?: [number, number];
      /** Appointment kinds listed here will not be shown on the daily scheduling list */
      patientListAppointmentKindBlacklist?: AppointmentKind[];
    };

    allowedStatuses?: AppointmentStatus[];
    defaultAcuity?: number;
    defaultPharmacyAcuity?: number;
    openTime?: string;
    closeTime?: string;
    minSeparatedTags: { frontloaded: number };
    slotDuration: number;
    startSeparation: number;
    /** Condition to unselect null staff in medonc, use true as default */
    excludeNullStaff?: boolean;
    /** If true, automatically exclude appointments assigned to staff that are not in the daily roster. */
    excludeOutsideRoster?: boolean;
    /** If true, automatically exclude appointments with null staff from MO daily scheduling runs unless manually included. */
    exemptLocations?: string[];
    /** @deprecated moved to mo_p2 */
    shortMovedLimits: [number, number];
    /** List of appointment types whose presence would trigger a (day, resource) pair to be designated as skippable */
    skippableAptTypes: string[];
    /** RO only for now, global rescheduling feature flag */
    useRescheduling: boolean;
    /** Prompts the user a cancellation reason when setting the status as cancelled, also requires a value */
    cancellationReasonRequired: boolean;
    maxCommentLength?: number;
    useOverbooking: boolean;
    moLabsTechnique?: string;
    createLabAppointments?: boolean;
    baseCalendarResources: [
      SchedulingStaffMember['staffId'],
      SchedulingResource['name'],
    ][];
    canRemoveFlags: boolean;
    statusColors: Partial<Record<AppointmentStatus, string>>;
    /** insitution info */
    hospitalName: string;
    hospitalAddress: string;
    hospitalPhone: string;
    hospitalPatientMessage: string;
  };
}

export type TeamList = Pick<Team, 'id' | 'name' | 'discipline' | 'institution'>;

export interface User {
  id: number;
  username: string | null;
  team?: Team;
  firstName: string | null;
  lastName: string | null;
  canSeePii: boolean;
  settings: Partial<Record<string, any>> & { version: number };
  isStaff: boolean;
  canChangeTeams: boolean;
  canRunJobs: boolean;
  canViewJobs: boolean;
  canModifyRoster: boolean;
}

export interface Institution {
  id: string;
  name: string;
}

export type SimpleUser = Omit<User, 'team'>;

export type TimelineDateRange = [Date, Date];

export type Network = {
  id: string;
  name: string;
  categories: ResourceCategory[];
  arrivalDays: boolean[];
  lastModified?: string;
};

export type ResourceCategory = {
  id: string;
  name: string;
  treatmentResources: TreatmentResource[];
};

export type TreatmentResource = {
  id: string;
  name: string;
  category?: string; // ResourceCategory
  openingHours: number[];
  tags: string[];
  isPlanning: boolean;
  isTreatment: boolean;
  isStaff: boolean;
};

export interface SchedulingResource {
  id: string;
  name: string;
  prettyName: string;
  abbreviation: string;
  category: string;
  order: number;
  emergencyMinutes: number;
  operatingHours: OperatingHours[];
  tags: string[];
  isPlanning: boolean;
  isTreatment: boolean;
  isConsult: boolean;
  active: boolean;
}

export interface SchedulingResourceHours {
  resource: SchedulingResource['name'];
  date: string; // Date
  openTime: string; // Date
  closeTime: string; // Date
}

export type OperatingHoursOverride = SchedulingResourceHours;

// start of Scheduling data types
export interface PatientPlan {
  id: string; // GrayOS DB id
  patId: string; // Hospital-wide ID of patient file
  comments: string;
  activities: CarePlanActivity[];
  badges: PatientBadge['id'][];
  carePlanName: string;
  carePlan: string | null; // id
  diagnosis: string;
  priority: number | null;
  internalPriority: number | null;
  dateReady: string; // Date when patient is ready for scheduling
  userDateReady: string | null; // User set date ready
  minDate: string; // Minimum date to look for a schedule
  dateDue: string | null;
  requestingDoctor: string | null;
  fixedDate: string | null;
  eligibleForRescheduling: boolean;
  unavailabilities: PatientUnavailability[];
  status: PatientPlanStatus;
}

export type PatientPlanActivity = CarePlanActivity & {
  minGap: number | null;
  maxGap: number | null;
  constraints?: ActivityConstraint;
};

export interface ActivityConstraint {
  timeConstraints: ROSchedulingConstraint[];
  dayConstraints: boolean[];
  linked: boolean;
}

export interface ROPatientPlan extends PatientPlan {
  planTemplate: string;
  isDelayBusinessDay: boolean;
  minPlanningDelay: number;
  earliestTreatmentDate: string | null;
  activities: PatientPlanActivity[];
  deterministicActivities: DeterministicPlanActivity[];
  daysLeft: number | null;
  eligibleMachines: EligibleMachine[];
  skipSkippableDays: boolean;
  planningDelayStrategy: SchedulingDelayStrategy;
  treatmentDelayStrategy: SchedulingDelayStrategy;
  validatedTimestamp: string;
  validatedBy: SimpleUser | null;
  sameTimeEveryday: boolean;
  txStartDate: string | null;
}

export type ROPatientPlanList = Omit<
  ROPatientPlan,
  'activities' | 'eligibleMachines'
>;

export type NewROSchedulingPlanInput = Pick<
  ROPatientPlan,
  'patId' | 'comments' | 'carePlan' | 'dateReady' | 'priority'
>;

export enum ROSchedulingTaskScope {
  All = 'all',
  TreatmentOnly = 'treatment_only',
  PlanningOnly = 'planning_only',
}

export interface ROSchedulingTask {
  id: string;
  plan: ROPatientPlan;
  assignedTo: SimpleUser | null;
  /**
   * Subset of this task's ROPatientPlan Activity ids
   */
  activities: CarePlanActivity['id'][];
  deterministicActivities: DeterministicActivity['id'][];
  status: TaskStatus;
  name: string;
  comments: string;
  scope: ROSchedulingTaskScope;
  hidden: boolean;
  externalId: string | null;
}

export interface ROSchedulingTaskList extends Omit<ROSchedulingTask, 'plan'> {
  plan: ROPatientPlanList;
}

export interface MOSchedulingTask {
  id: string;
  assignedTo: SimpleUser | null;
  status: TaskStatus;
  name: string;
  comments: string;
  hidden: boolean;
  externalId: string | null;

  plan: MOPatientPlan;
  fromCycle: number;
  toCycle: number;
}

export interface MOSchedulingTaskList extends Omit<MOSchedulingTask, 'plan'> {
  plan: MOPatientPlanList;
}

export type SchedulingTask = MOSchedulingTask | ROSchedulingTask;
export type SchedulingTaskList = MOSchedulingTaskList | ROSchedulingTaskList;

export enum PatientPlanStatus {
  Completed = 0,
  Cancelled = 1,
  Pending = 2,
  Deleted = 3,
}

export enum TaskStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Completed = 'completed',
  InReview = 'in_review',
  Closed = 'closed',
}

export interface PatientDetail<CarePlan = ROPatientPlanList | MOPatientPlan> {
  appointments: PatientAppointment[];
  carePlans: CarePlan[];
}

export interface ROPatientDetail {
  appointments: PatientAppointment[];
  carePlans: ROPatientPlanList[];
}

export interface MOPatientDetail {
  appointments: MOPatientAppointment[];
  carePlans: MOPatientPlan[];
}

export interface PatientUnavailability {
  reason: string;
  fromDate: string; // YYYY-MM-DD
  toDate: string | null;
  countInDelay: boolean;
}

/** Be careful using groupBy with staffBooked as `null` will be coerced as string when used as an object key */
export interface MOPatientAppointment extends BaseAppointment {
  carePlanName: string | null;
  characteristics: string[]; // chair characteristics picked from metaTags
  rtApt?: PatientAppointment;
  nursingAcuity?: number | null;
  dailyAcuity?: number | null;
  nursingAcuityVector?: number[];
  selected?: boolean; // frontend only field
  staffLocked?: boolean; // frontend only field
  activity?: MOActivity | null;
}

export interface MOActivity {
  identifier: string;
  cycleNumber: number;
  dayOfCycle: number | null;
  pharmacyAcuity: number;
  nursingAcuity: number;
  labValidityPeriod: number | null;
  /* frontend field only */
  protocolAptId?: number;
}

export type MOPatientAppointmentParameter = MOPatientAppointment & {
  selected: boolean;
  staffLocked: boolean;
  nursingAcuity: number;
  dailyAcuity: number;
};

export interface Activity {
  id: string;
  duration: number;
  kind: AppointmentKind;
  modality: ActivityModality;
  technique: string;
  description: string;
  defaultDuration: number;
  /** matches to SchedulingResource.tags */
  tags: string[];
  lastModified: string;
  color: string | null;
  overlapAllowed: boolean;
  defaultInertia: number;
  defaultTime: string | null;
  defaultLocation: string | null;
  defaultStaff: string | null;
  active: boolean;
}

export interface BaseCPActivity
  extends Pick<
    Activity,
    | 'id'
    | 'duration'
    | 'kind'
    | 'modality'
    | 'technique'
    | 'description'
    | 'tags'
    | 'overlapAllowed'
  > {
  index: number; // order disregarding same day order
  order: number; // order relative to interval
  flags: string[];
  accessories: string[];
  interval: number | null;
  minGap: number | null;
  maxGap: number | null;
  tempId?: string;
}

export interface PlanningActivity extends BaseCPActivity {
  kind: AppointmentKind.planning;
}

export interface ConsultActivity extends BaseCPActivity {
  kind: AppointmentKind.consult;
}

export interface StaffActivity extends BaseCPActivity {
  kind: AppointmentKind.consult | AppointmentKind.staff;
}

export interface TreatmentActivity extends BaseCPActivity {
  kind: AppointmentKind.treatment;
}

export interface TreatmentPlanActivity extends TreatmentActivity {
  constraints: ActivityConstraint;
}

export interface StaffPlanActivity extends StaffActivity {
  constraints: ActivityConstraint;
}

export interface ConsultPlanActivity extends ConsultActivity {
  constraints: ActivityConstraint;
}

export interface PlanningPlanActivity extends PlanningActivity {
  constraints: ActivityConstraint;
}

type DeterministicActivityWithRelatedActivity =
  | {
      relatedPlanActivity: CarePlanActivity['id'];
      minInterval: number;
      maxInterval: number;
    }
  | {
      relatedPlanActivity: null;
      minInterval: null;
      maxInterval: null;
    };

export type DeterministicActivity = {
  id: string;
  technique: CarePlanActivity['technique'];
  duration: number;
  isIntervalBusinessDays: boolean;
  location: string | null;
  staffAssigned: string | null;
  flags: string[];
  tempId?: string;
} & DeterministicActivityWithRelatedActivity;

export type DeterministicPlanActivity = DeterministicActivity;

export type CarePlanActivity =
  | BaseCPActivity
  | PlanningActivity
  | StaffActivity
  | TreatmentActivity;

export interface PlanningActivityConstraints {
  planningActivityId: string;
  constraints: ROSchedulingConstraint[];
  dayConstraint: SchedulingDayConstraint;
  label?: string; // for display purposes only
}

export type MOProtocolList = Pick<
  MOProtocol,
  | 'id'
  | 'name'
  | 'administrationRoute'
  | 'description'
  | 'nursingAcuity'
  | 'pharmacyAcuity'
  | 'active'
  | 'frequency'
>;

export interface MOProtocol {
  id: number;
  name: string;
  description: string;
  comments: string;
  pharmacyAcuity: number | null;
  nursingAcuity: number | null;
  cycles: ProtocolCycle[];
  administrationRoute: ProtocolAdministrationRoute;
  frequency: number;
  active: boolean;
}

export interface ProtocolCycle {
  cycleNumber: number | null;
  cycleDuration: number;
  appointments: ProtocolAppointment[];
  otherAppointments: ProtocolOtherAppointment[];
  comments: string;
}

export interface ProtocolAppointment {
  /** number when saved to the db */
  id: number | string;
  identifier: string;
  dayOfCycle: number;
  labValidityPeriod: number | null;
  duration: number;
  activity: string;
  flags: string[];
  nursingAcuity: number | null;
  pharmacyAcuity: number | null;
  comments: string;
  /** @deprecated to be removed */
  parts: ProtocolAppointmentPart[];
  timeConstraint: StringConstraint | null;
}

export interface ProtocolOtherAppointment {
  id: number | string;
  identifier: string;
  dayOfCycle: number | null;
  activity: CarePlanActivity['id'];
  duration: number;
  modality?: ActivityModality;
  resource: string | null;
  staffAssigned: number | null;
  flags: string[];
  comments: string;
}

export interface ProtocolAppointmentPart {
  index: number;
  identifier: string;
  nursingAcuity: number | null;
  pharmacyAcuity: number | null;
  comments: string;
}

export interface TreatmentProgramAppointment {
  id: string;
  day: number;
  cycle: number;
  protocolAptId: ProtocolAppointment['id'];
  description: string;
  labs: number | null;
  flags: SchedulingAppointmentFlag['name'][];
  activity: CarePlanActivity['id'];
  duration: number;
  pharmacyAcuity: ProtocolAppointment['pharmacyAcuity'];
  nursingAcuity: ProtocolAppointment['nursingAcuity'];
  timeConstraint: ProtocolAppointment['timeConstraint'];
}

export interface TreatmentProgramOtherAppointment {
  id: string;
  cycle: number;
  dayOfCycle: number | null;
  otherAptId: ProtocolOtherAppointment['id'];
  description: string;
  modality: ActivityModality | null;
  activity: CarePlanActivity['id'];
  resource: string | null;
  staffAssigned: number | null;
  flags: string[];
  duration: number;
}

export interface ProtocolComponent {
  name: string;
  duration: number;
  cyclesApplicable: string[];
  day: number;
  labs: number;
  comments: string;
  pharmacyAcuity: number | null;
  nursingAcuity: number | null;
  activity: CarePlanActivity['id'];
  flags: SchedulingAppointmentFlag['name'][];
}

export interface TreatmentProgramForm {
  treatmentProgram: TreatmentProgramAppointment[];
  otherAppointments: TreatmentProgramOtherAppointment[];
}

export interface TreatmentProgram {
  startCycle: number;
  endCycle: number;
  cycles: TreatmentProgramCycle[];
}

export interface TreatmentProgramCycle {
  cycleNumber: number;
  cycleDuration: number;
  linked: boolean;
  appointments: TreatmentProgramAppointment[];
  otherAppointments: TreatmentProgramOtherAppointment[];
}

export interface PreTreatmentActivity {
  duration: number;
  delay: number;
  activity: CarePlanActivity['id'];
  flags: SchedulingAppointmentFlag['name'][];
  comments: string;
}

export enum ProtocolAdministrationRoute {
  Intravenous = 'IV',
  Intravesical = 'IVes',
  Subcutaneous = 'SC',
  Hospitalized = 'HO',
  PerOs = 'PO',
  Other = 'OT',
}

export interface SchedulingCarePlan {
  id: string;
  name: string;
  category: string;
  priority: number;
  minimumDelay: number; // Default delay between planning and tx
  isDelayBusinessDay: boolean;
  activities: CarePlanActivity[];
  deterministicActivities: DeterministicActivity[];
  eligibleMachines: EligibleMachine[];
  eligibleDoctors: EligibleMachine[];
  weekendsAsInterval: boolean;
  skipSkippableDays: boolean;
  eligibleForRescheduling: boolean;
  active: boolean;
}

export interface SchedulingCarePlanList
  extends Pick<
    SchedulingCarePlan,
    'id' | 'category' | 'name' | 'priority' | 'minimumDelay' | 'active'
  > {
  frequency: number;
  numActivities: number;
}

/**
 * This used to be the structure of SchedulingCarePlan before we merged activities
 * We still need them separate in the forms so it's not complicated to keep track of the
 * activity index, we will just combine them before saving
 */
export interface SchedulingCarePlanForm
  extends Omit<SchedulingCarePlan, 'activities'> {
  txActivities: TreatmentActivity[];
  planningActivities: (PlanningActivity | StaffActivity)[];
}

export type PatientPlanForm = {
  txActivities: TreatmentPlanActivity[];
  planningActivities: (
    | PlanningPlanActivity
    | ConsultPlanActivity
    | StaffPlanActivity
  )[];
  deterministicActivities: DeterministicPlanActivity[];
};

export type CarePlanForm = SchedulingCarePlanForm | PatientPlanForm;

export interface SchedulingTreatmentResource extends SchedulingResource {
  isStaff: boolean;
  score: number;
}

export type StringConstraint = Brand<string, 'StringConstraint'>;
export type StringConstraintRange = { lowerBound: string; upperBound: string };

export interface SchedulingConstraint {
  id: string;
  constraintType: ConstraintType;
  range: { lowerBound: Date; upperBound: Date }; // Tx must start within a given range
  /*
   Maybe we need to point to a specific fraction here... I could imagine
   a patient having scheduling constraints on some days but not others.
  */
}

export interface ROSchedulingConstraint
  extends Omit<SchedulingConstraint, 'range'> {
  range: StringConstraintRange;
}

export type SchedulingDayConstraint = {
  days: boolean[];
};

export enum EligibleMachinePreferenceLevel {
  Eligible,
  Preferred,
  Favorite,
}

export interface EligibleMachine {
  resource: string;
  /** @deprecated use preferenceLevel instead */
  preferred: boolean;
  preferenceLevel: EligibleMachinePreferenceLevel;
  tags: string[];
}

export enum SchedulingMode {
  Full = 'full',
  PlanningOnly = 'planning_only',
  TreatmentOnly = 'treatment_only',
}

export enum SchedulingDelayStrategy {
  /* Aim to schedule appointments as soon as possible */
  ASAP = 'ASAP',
  /* Aim to schedule appointments "just in time" (max allowable delay) */
  JIT = 'JIT',
}

export interface SchedulingParameters {
  taskId: string;
  task?: ROSchedulingTaskList;
  /**
   * list of ROPatientPlan activity ids
   */
  taskActivities: ROSchedulingTask['activities'];
  taskDetActivities: ROSchedulingTask['deterministicActivities'];
  patientPlan: ROPatientPlan;
  carePlan: SchedulingCarePlan & { activities: PatientPlanActivity[] };
  sameTimeEveryday: boolean;
  isDelayBusinessDay: boolean;
  earliestDate: string;
  earliestTreatmentDate: string | null;
  schedulingMode?: SchedulingMode; // Full by default
  skippableDays: SkipDay[];
  // frontend only fields
  isInitialized?: boolean;
  isValidated?: boolean;
  isExcluded?: boolean; // when removed from the schedule result

  /** @deprecated moved to activity.constraints */
  planningConstraints: PlanningActivityConstraints[];
  /** @deprecated moved to activity.constraints */
  txConstraints: ROSchedulingConstraint[];
  /** @deprecated moved to activity.constraints */
  txDaysConstraint: SchedulingDayConstraint;

  planningDelayStrategy: SchedulingDelayStrategy;
  treatmentDelayStrategy: SchedulingDelayStrategy;
}

export type SchedulingParametersInput = Omit<
  SchedulingParameters,
  'patientPlan' | 'task'
> & {
  carePlanName: SchedulingCarePlan['name'];
  patientId: ROPatientPlan['patId'];
  planId: ROPatientPlan['id'];
};

export interface ROSchedulingInput {
  patientPlans: SchedulingParametersInput[];
  useRescheduling: boolean;
  useOverbooking: boolean;
}

export interface SkipDay {
  id: string;
  name: SchedulingResource['name'];
  date: Appointment['scheduledTime'];
  aptType: CarePlanActivity['technique'];
  selected: boolean;
}

export interface PatientDemographics {
  id: string;
  firstName: string;
  lastName: string;
  addressString: string;
  insuranceId: string;
  phoneCell: string;
  phoneHome: string;
  postal: string;
  defaultReferringMd: number | null;
  dateOfBirth: string | null;
  inpatientLocation: string | null;
  deceased: boolean;
}

export interface PatientDistance {
  patientId: string;
  postalCode: string;
  distances: {
    campus: string;
    distance: number;
  }[];
}

export type SchedulingJobResponse =
  | ROSchedulingJobResponse
  | MOSchedulingJobResponse;

interface JobResponse {
  id: string;
  progress: number;
  status: SchedulingJobStatus;
  savedAppointments: Appointment[];
  dateCreated: string;
  userCreated: SimpleUser;
  team: string;
}

export interface ROScheduleLocalState {
  modifications?: Modification[];
  rejectedMoves?: string[];
}

export interface ROSchedulingJobResponse extends JobResponse {
  kind: Disciplines.RadOnc;
  data: ROSchedulingInput;
  results: {
    patientAppointments?: ROScheduleResult;
  };
  schedulingAttempts: ROSchedulingAttempt[];
}

export type ROSchedulingJobResponseList = Omit<
  ROSchedulingJobResponse,
  'data'
> & { data: SimpleROSchedulingJobParameters[] };

/**
 * Trimmed version of ROSchedulingJobParameters to reduce payload size on list endpoints
 */
export type SimpleROSchedulingJobParameters = Pick<
  SchedulingParametersInput,
  'taskId' | 'patientId' | 'planId' | 'carePlanName' | 'isExcluded'
>;

export type ROSchedulingAttempt = Pick<
  ROSchedulingJobResponse,
  'id' | 'userCreated' | 'progress' | 'data' | 'status'
> & {
  results: { patientAppointments: ROScheduleResult };
  localState: {
    patientAppointments: ROScheduleResult;
  } & ROScheduleLocalState;
};

export interface ROScheduleResult {
  movedAppointments: Appointment[];
  newAppointments: ROAppointmentResult[];
  rescheduledPatients: RORescheduledPatient[];
}

export interface ROAppointmentResult {
  patientId: string;
  cpId: string;
  planningActivities: PatientAppointment[];
  txActivities: PatientAppointment[];
  deterministicActivities: DetermninisticAppointment[];
}

export interface RORescheduledPatient {
  patientId: string;
  cpId: string;
  rescheduledAppointments: PatientAppointment[];
}

export interface MOSchedulingJobResponse extends JobResponse {
  kind: Disciplines.MedOnc;
  userCreated: User;
  dateTargeted: string;
  data: MOSchedulingJobParameters;
  results: MOPatientAppointment[];
  schedulingAttempts: MOSchedulingAttempt[];
  label: string;
  lastModified: string;
  isFavorite: boolean;
}

export interface MOP1SchedulingJobResponse extends JobResponse {
  kind: SchedulingJobKind.MedOncP1;
  userCreated: User;
  dateTargeted: string;
  data: MOP1SchedulingParametersInput[];
  results: {};
  schedulingAttempts: MOP1SchedulingAttempt[];
  label: string;
  lastModified: string;
  isFavorite: boolean;
}

export type MOSchedulingJobResponseList = Omit<
  MOSchedulingJobResponse,
  'data'
> & {
  data: SimpleMOSchedulingJobParameters;
};

export type MOP1SchedulingJobResponseList = Omit<
  MOP1SchedulingJobResponse,
  'data'
> & {
  data: SimpleMOP1SchedulingJobParameters[];
};

export type MOSchedulingAttempt = Pick<
  MOSchedulingJobResponse,
  'id' | 'userCreated' | 'progress' | 'data' | 'status'
> & { results: MOPatientAppointment[]; localState: MOPatientAppointment[] };

export type MOP1SchedulingAttempt = Pick<
  MOP1SchedulingJobResponse,
  'id' | 'userCreated' | 'progress' | 'data' | 'status'
> & {
  results: MOP1PatientAppointment[];
  localState: MOP1PatientAppointment[]; // saved state
};

export interface MOSchedulingJobParameters {
  date: Date;
  parameters: MedOncParameters;
  patients: MOPatientAppointment[];
}

/**
 * Trimmed version of MOSchedulingJobParameters to reduce payload size on list endpoints
 */
export interface SimpleMOSchedulingJobParameters {
  patients: { id: string }[];
  parameters: { maxMoved: number | null; maxMoveTime: number | null };
}

/**
 * Trimmed version of MOP1SchedulingJobParameters to reduce payload size on list endpoints
 */
export interface SimpleMOP1SchedulingJobParameters {
  taskId: string;
  planId: string;
  patId: string;
  protocolName: string;
  treatmentProgram: TreatmentProgram;
}

export type SchedulingAttempt = MOSchedulingAttempt | ROSchedulingAttempt;

export enum SchedulingJobKind {
  RadOnc = 'radonc',
  MedOncP1 = 'medonc_p1',
  MedOncP2 = 'medonc_p2',
}

// end of Scheduling data types

// Schedule for a resource (e.g chemo chair, linac)
/**
 * @deprecated
 */
export interface ResourceSchedule {
  name: string;
  prettyName: string;
  timeSlotInterval: number;
  tags: string[];
  schedule: WorkDay[];
  operatingHours: OperatingHours;
  isPlanning: boolean;
  isTreatment: boolean;
  isStaff: boolean;
}

export interface SchedulingJobs<Result = SchedulingJobResponse> {
  count: number;
  next: string | null;
  previous: string | null;
  results: Result[];
}

export interface Job {
  id: string;
  kind: string;
  status: number;
  dateCreated: Date;
  data: {
    date: string;
    patients: { id: string }[];
  };
  dateTargeted: Date;
}

// Appointments in a day.
export interface WorkDay {
  date: Date | string;
  operatingHours: OperatingHours;
  appointments: Appointment[];
}

// Opening and closing times for a day of the week.
export interface OperatingHours {
  openTime: string; // Time
  closeTime: string; // Time
}

/**
 * AppointmentStatus
 * The free/busy status of an appointment.
 * @see https://hl7.org/fhir/ValueSet/appointmentstatus
 */
export enum AppointmentStatus {
  /**
   * None of the participant(s) have finalized their acceptance
   * of the appointment request, and the start/end time might
   * not be set yet.
   */
  PROPOSED = 'proposed',
  /**
   * Some or all of the participant(s) have not finalized
   * their acceptance of the appointment request.
   * @alias draft
   */
  PENDING = 'pending',
  /**
   * All participant(s) have been considered and the appointment
   * is confirmed to go ahead at the date/times specified.
   * @alias accepted
   */
  BOOKED = 'booked',
  /**
   * The patient/patients has/have arrived and is/are waiting to be seen.
   */
  ARRIVED = 'arrived',
  /**
   * The planning stages of the appointment are now complete,
   * the encounter resource will exist and will track further status changes.
   *
   * Note that an encounter may exist before the appointment status is
   * fulfilled for many reasons.
   * @alias complete
   */
  FULFILLED = 'fulfilled',
  /**
   * The appointment has been cancelled.
   * @alias abandoned
   */
  CANCELLED = 'cancelled',
  /**
   * Some or all of the participant(s) have not/did not appear
   * for the appointment (usually the patient).
   * @alias suspended
   */
  NOSHOW = 'noshow',
  /**
   * This instance should not have been part of this patient's medical record.
   * @alias error
   * @alias deleted
   */
  ENTERED_IN_ERROR = 'entered-in-error',

  /**
   * When checked in, all pre-encounter administrative work is complete,
   * and the encounter may begin.
   * (where multiple patients are involved, they are all present).
   * @alias partial
   */
  CHECKED_IN = 'checked-in',
  /**
   * The appointment has been placed on a waitlist, to be scheduled/confirmed
   * in the future when a slot/service is available.
   * A specific time might or might not be pre-allocated.
   */
  WAITLIST = 'waitlist',
}

export interface AppointmentTimestamps {
  aptId: string;
  arrivedTs: string | null;
  cancelledTs: string | null;
  completedTs: string | null;
  startedTs: string | null;
  userDefinedTimestamps: UserDefinedTimestamp[];
}

interface UserDefinedTimestamp {
  location: string;
  type: string;
  timestamp: string;
}

export enum ROCalendarMode {
  Scheduled = 'scheduled',
  Prediction = 'prediction',
  Timestamps = 'timestamps',
}

export type Availability = Pick<
  Appointment,
  'id' | 'scheduledTime' | 'technique' | 'duration' | 'location' | 'staffBooked'
> & {
  default?: Partial<
    Pick<Appointment, 'scheduledTime' | 'location' | 'staffBooked'>
  >;
};

// Appointment scheduled on a resource
export interface BaseAppointment {
  id: string;
  /**
   * This is readonly but ts' readonly modifier sucks
   * so dont touch this
   */
  metaTags: string[];
  state: Record<string, string>;
  flags: string[];
  location: string;
  scheduledTime: string;
  duration: number;
  patId: string | null;
  carePlan: string | null;
  site: string;
  kind: AppointmentKind;
  aptStatus: AppointmentStatus;
  comments?: string;
  technique: string;
  techniqueLabel: string;
  staffBooked: string | null;
  accessories: string[];
  dateModified: string;
  lastNotifiedTime: string | null;
  originalAppointment?: Omit<
    BaseAppointment,
    'originalAppointment' | 'metaTags'
  >;
  availabilityFound?: Availability;
  dateWhenBooked?: string;
  inertia?: number;
  overlapAllowed?: boolean;

  timeConstraint?: ROSchedulingConstraint | null;
  cancellationReason: string | null;

  // frontend only fields
  source?: string;
  defaultWave?: number | null;

  /* predicted attributes */
  attributes?: Partial<AppointmentAttributes>;

  timestamps?: AppointmentTimestamps;
}

export interface AppointmentAttributes {
  duration: number;
  __durationPrediction: DurationPrediction;
  cancellationProbability: number;
  __cancellationPrediction: CancellationPrediction;
  explanation: SHAPExplanation;
}

export interface PatientAppointment extends BaseAppointment {
  kind:
    | AppointmentKind.planning
    | AppointmentKind.treatment
    | AppointmentKind.pharmacy
    | AppointmentKind.consult
    | AppointmentKind.staff
    | AppointmentKind.service
    | AppointmentKind.lab;
}

export interface StaffAppointment extends BaseAppointment {
  kind: AppointmentKind.consult | AppointmentKind.staff;
}

export interface OrgAppointment extends BaseAppointment {
  kind:
    | AppointmentKind.QA
    | AppointmentKind.block
    | AppointmentKind.break
    | AppointmentKind.lunch
    | AppointmentKind.meeting
    | AppointmentKind.memo
    | AppointmentKind.emergency
    | AppointmentKind.reserved;
}

export interface OtherAppointment extends BaseAppointment {
  kind: AppointmentKind.other | AppointmentKind.unknown;
}

export interface MOP1PatientAppointment extends PatientAppointment {
  carePlanName: string | null;
  treatmentId?: string;
  cycleNum?: number;
}

export interface DetermninisticAppointment extends BaseAppointment {
  cpActivity?: string;
  relatedApt?: string;
}

export type PartialAppointment = DetermninisticAppointment & {
  scheduledTime: string | null;
  location: string | null;
  staffAssigned: string | null;
};

export type Appointment =
  | PatientAppointment
  | OrgAppointment
  | StaffAppointment
  | OtherAppointment;

export type AppointmentChange = Partial<Appointment['originalAppointment']>;

export interface PredictionConfidenceInterval {
  lower: number;
  upper: number;
  alpha: number;
  coverageGuarantee: number | null;
}

export interface FeatureAttribution {
  name: string;
  value: number;
  header: string;
}

export interface SHAPExplanation {
  featureAttributions: FeatureAttribution[];
  remainder: number;
  expectedValue: number;
}

export interface ScalarPrediction {
  value: number;
  confidenceInterval: PredictionConfidenceInterval;
  explanation: SHAPExplanation;
}

export interface DurationPrediction {
  appointmentId: Appointment['id'];
  predictedDuration: ScalarPrediction;
  predictedC2c: ScalarPrediction;
  expectedEndTime: string;
}

export enum CancellationPredictionExplanationCategories {
  PatientHistory = 'PT_HISTORY',
  AppointmentData = 'APT_DATA',
  HospitalTrend = 'HOSPITAL_TREND',
  AppointmentDate = 'APT_DATE',
  Other = 'OTHER',
}

export interface CancellationPrediction {
  appointmentId: Appointment['id'];
  value: number;
  explanation: SHAPExplanation;
  confidence: number;
}

export interface DurationPredictionModel {
  id: string;
  name: string;
  version: number;
}

export interface CancellationPredictionModel {
  id: string;
  name: string;
  version: number;
}

// Format of metadata exported by the app

export interface MetaDataCount {
  appointments: number;
  [tagName: string]: any;
}
export interface DailyMetaData extends MetaDataCount {
  date: string;
}

/**
 * Combination of DailyCounts and MetaDataCount
 */
export interface MetaCount {
  name: string;
  icon: string;
  prettyName: string;
  color: string;
  count: number;
  limit?: number;
  diff?: number;
}

export interface SimulationCarePlan
  extends Omit<SchedulingCarePlan, 'eligibleDoctors'> {
  probability: number;
}

export interface SchedulingAccessory {
  id: string;
  category: string;
  name: string;
  prettyName: string;
  team?: string;
}

export enum ChairAssignmentOptions {
  SHARED = 'shared',
  UNSHARED = 'unshared',
  ASSIGNED = 'assigned',
  SEQUENTIAL = 'sequential',
}

export interface MOP2MaxMoveParameters {
  maxMoved: number | null;
  maxMoveTime: number | null;
}

export interface MedOncParameters
  extends Omit<MORoster, 'id'>,
    MOP2MaxMoveParameters {
  rosterId: number;
}

export interface MOTagAssignment {
  id: number;
  nurse: RosterNurse['nurse'];
  staffId: RosterNurse['staffId'];
  tag: string;
}
export interface MOTagBalanceRule {
  id: number;
  name: string; // label
  tag: string;
  exemptNurses: RosterNurse['staffId'][];
}

export interface RosterNurse {
  nurse: SchedulingStaffMember['id'];
  staffId: SchedulingStaffMember['staffId'];
  prettyName: SchedulingStaffMember['prettyName'];
  startTime: string;
  endTime: string;
  /** HH:mm:ss */
  lunchTime: string | null;
  group: string;
  dailyAcuityTarget: number; // 50-200
  prelunchAptLimit: number;
  totalAptLimit: number;
  order: number;
  isVirtual: boolean;
  mustBeScheduled: boolean;
}

export interface RosterChair {
  chair: SchedulingResource['id'];
  name: SchedulingResource['name'];
  prettyName: SchedulingResource['prettyName'];
  allowedNurses: number[]; // nurse IDs
  characteristics: string[];
  exclusiveCharacteristics: string[];
  group: string;
  order: number;
  isVirtual: boolean;
}

export interface MORoster {
  id: number;
  name: string;
  nurses: RosterNurse[];
  chairs: RosterChair[];
  tagAssignments: number[];
  balanceRules: number[];
  chairAssignment: ChairAssignmentOptions;
  flagLimits: FlagLimit[];
}

export interface DefaultDailyRoster {
  id: number;
  day: number; // day indexed 0
  roster: MORoster['id'];
}

export interface FlagLimit {
  name: string;
  limit: number;
  type: 'flag' | 'group' | 'metatag';
}

export interface DailyLimitCount extends FlagLimit {
  count: number;
}

export type DailyLimitCountByDate = Record<string, DailyLimitCount[]>;

export interface MODailyRoster extends MORoster {
  date: string;
  published: boolean;
}

// Unsaved
export type DefaultMODailyRoster = Omit<MODailyRoster, 'id'> & { id: null };

export type MODailyRosterList = Omit<
  MODailyRoster,
  'id' | 'nurses' | 'chairs' | 'tagAssignments' | 'balanceRules'
> & {
  id: number | null;
  nurseCount: number;
  chairCount: number;
};

export interface VirtualResources {
  nurse?: RosterNurse;
  chair?: RosterChair;
}
export interface SchedulingStaffMember {
  id: number;
  staffId: string;
  firstName: string;
  lastName: string;
  prettyName: string;
  kind: StaffMemberKind;
  abbreviation: string;
  color?: string;
}

export interface ScheduleRule {
  id: string;
  staffId: string;
  resource: string;
  description: string;
  scheduledTime: string;
  priority: number;
  duration: number;
  kind: ScheduleRuleKind;
  color: string | null;
}

export interface SchedulingAppointmentFlag {
  id: string;
  name: string;
  prettyName: string;
  abbreviation: string;
  active: boolean;
  group: string;
  groupWeight: number;
}

export interface PatientBadge {
  id: number;
  prettyName: string;
  description: string;
  alertLevel: 'info' | 'warn' | 'criticial';
}

export interface RuleDay {
  date: Date | string;
  rules: ScheduleRule[];
}

export interface RuleSchedule {
  name: string;
  schedule: RuleDay[];
}

export interface CalendarResource {
  id: string;
  title: string;
  extendedProps?: {
    [extendedProp: string]: any;
    isConsult?: boolean;
  };
}

export interface ScheduleResourceTypes {
  locations: SchedulingResource['name'][];
  staff: SchedulingStaffMember['staffId'][];
}

export interface MOResource {
  nurse: RosterNurse['staffId'];
  chairs: RosterChair['name'][];
}

export interface MOResourcePair {
  nurse: RosterNurse['staffId'];
  chair: RosterChair['name'];
}

export type MOResourceDictionary = Record<
  RosterNurse['staffId'],
  RosterChair['name'][]
>;

export enum EHRAppointmentStatus {
  Unsent = 'UNSENT',
  Sending = 'SENDING',
  Accepted = 'ACCEPTED',
  Validated = 'VALIDATED',
  Rejected = 'REJECTED',
  Error = 'ERROR', // grayos errors
  ExternalError = 'EXTERNAL_ERROR', // error in external mode
  LocalError = 'LOCAL_ERROR', // error in local ownership mode, treated as success in the frontend
  OutOfSync = 'OOS',
  WontSend = 'WONTSEND',
}

export enum EHRAppointmentOrigin {
  AlgorithmModified = 'ALGORITHM_MODIFIED',
  AlgorithmUnmodified = 'ALGORITHM_UNMODIFIED',
  User = 'USER',
}

export interface EHRAppointment {
  id: string;
  team: string;
  status: EHRAppointmentStatus;
  batchId: string;
  patientId: string | null;
  grayAptId: Appointment['id'];
  aptStatus: Appointment['aptStatus'];
  emrAptId: string; // can be blank
  aptType: Appointment['technique'];
  location: Appointment['location'];
  duration: Appointment['duration'];
  aptStart: Appointment['scheduledTime'];
  staff: Appointment['staffBooked'];
  aptComment: Appointment['comments'];
  flags: Appointment['flags'];
  cancellationReason: Appointment['cancellationReason'];
  wave: number | null;
  lastModified: string;
  dateCreated: string;
  user: SimpleUser;
  originalAppointment: EHRAppointmentOriginalAppointment | null;
  moPlanActivityData?: MOPlanActivityData;
}

export type EHRAppointmentOriginalAppointment = Pick<
  BaseAppointment,
  | 'duration'
  | 'location'
  | 'technique'
  | 'comments'
  | 'staffBooked'
  | 'scheduledTime'
  | 'dateModified'
  | 'aptStatus'
  | 'flags'
>;

export type EHRAppointmentInput = (Appointment | MOPatientAppointment) & {
  origin?: EHRAppointmentOrigin;
  isWave?: boolean;
  moPlanActivityData?: MOPlanActivityData;
};

export interface EHRMessageWithAppointment extends EHRAppointment {
  appointment?: Appointment;
}

export type AppointmentConflict<Appt extends Appointment> = {
  ours: Appt;
  external: Appt;
};

export type MOAppointmentConflict = AppointmentConflict<MOPatientAppointment>;

export enum OverlapSeverity {
  Low = 1,
  Medium = 10,
  High = 100,
}

/**
 * ROActivePlan's Medical info types
 */

export enum MedicalInfoStatus {
  Approved = 'approved',
  Pending = 'pending',
  Removed = 'removed',
  Cancelled = 'cancelled',
  NeedReview = 'need_review',
}

export enum MedicalInfoOrigin {
  Original = 'original',
  Added = 'added',
  Removed = 'removed',
}

export interface MedicalInfoCarePlan {
  name: string;
  components: MedicalInfoComponent[] | any[];
  componentKeys: (keyof MedicalInfoComponent)[] | string[];
  comments: string;
}

export interface MedicalInfoComponent {
  text: string;
  order: number;
  status: MedicalInfoStatus;
  origin: MedicalInfoOrigin;
  dueDate: string;
}

export interface MedicalInfoQuestion {
  question: string;
  answer: string;
}

export interface MedicalInfoQuestionnaire {
  name: string;
  date: string;
  status: MedicalInfoStatus;
  questions: MedicalInfoQuestion[];
}

export interface MedicalPrescription {
  date: string;
  name: string;
  status: MedicalInfoStatus;
  [instruction: string]: string;
}

export interface MedicalInfoNote {
  type: string;
  title: string;
  dateCreated: string;
  status: MedicalInfoStatus;
}

export interface MedicalInfoTask {
  name: string;
  dateDue: string;
  status: MedicalInfoStatus;
  comments: string;
}

export interface MedicalInfoOrder {
  name: string;
  dateDue: string;
  status: MedicalInfoStatus;
  comments: string;
}

export interface MedicalInfoRTPrescription {
  name: string;
  dateCreated: string;
  status: MedicalInfoStatus;
  comments: string;
  technique: string;
  modality: string;
  controlReason: string;
  target: string;
  units: string;
  frequency: string;
}

export interface MedicalInfo {
  carePlanInfo: MedicalInfoCarePlan;
  questionnaires: MedicalInfoQuestionnaire[];
  rtPrescription: MedicalInfoRTPrescription[];
  tasks: MedicalInfoTask[];
  notes: MedicalInfoNote[];
  orders: MedicalInfoOrder[];
}

export interface MOPatientPlan extends PatientPlan {
  cycles: ProtocolCycle[];
  nursingAcuity: number | null;
  pharmacyAcuity: number | null;
  planTemplate: MOProtocol['id'];
}

export interface MOPatientPlanList extends Omit<MOPatientPlan, 'cycles'> {}

export type MOP1SchedulingParameters = {
  id: string;
  patId: string;
  taskId: MOSchedulingTask['id'];
  planId: MOPatientPlan['id'];
  startCycle: number;
  endCycle: number;
  protocolName: string;
  earliestDate: string;
  treatmentProgram: TreatmentProgram;
  txDaysConstraint: SchedulingDayConstraint;
  isValidated?: boolean;
} & (
  | {
      isInitialized: false;
      patientPlan: MOPatientPlanList;
    }
  | {
      isInitialized: true;
      patientPlan: MOPatientPlan;
    }
);

export interface MOPlanActivityData {
  dayOfCycle: number | null;
  identifier: string;
  template: ProtocolAppointment['id'] | null;
  cycleNumber: number;
  labValidityPeriod: ProtocolAppointment['labValidityPeriod'];
  pharmacyAcuity: number;
  nursingAcuity: number;
}

export type MOP1SchedulingParametersInput = Omit<
  MOP1SchedulingParameters,
  'patientPlan' | 'protocol'
>;

export enum MOSchedulingWizardStage {
  ListView = 0,
  ProtocolCustomizationView = 1,
  TreatmentProgramPreview = 2,
  Constraints = 3,
}

// Fullcalendar types
export type GrayEventInput = EventInput & {
  id: string;
  extendedProps?: Appointment & { secondary?: boolean };
};
export type EventChangeArg = {
  event: EventImpl | EventApi;
  relatedEvents: EventImpl[] | EventApi[];
};

export interface CancellationReason {
  index: number;
  text: string;
}

export enum CalendarColorMode {
  Technique = 'technique',
  ByNurse = 'by-nurse',
  Status = 'status',
  Kind = 'kind',
  Inertia = 'inertia',
  CancellationPrediction = 'cancellation-prediction',
}

export enum ListViewModes {
  Normal = 'normal',
  CancellationPrediction = 'cancellation-prediction',
}

/* Concierge Types */
export enum ConciergeModes {
  Rescheduling = 'rescheduling',
}

export interface ConciergeReschedulingPlan {
  id: string;
  patId: string;
  plan: ROPatientPlanList;
  delay: number; // days
  planningDelayStrategy: SchedulingDelayStrategy;
  treatmentDelayStrategy: SchedulingDelayStrategy;
  scope: ConciergeJobScope;
  selected?: boolean;
}

export interface ConciergeReschedulingInputData {
  planId: string;
  patId: string;
  carePlanName: string;
  planningDelayStrategy: SchedulingDelayStrategy;
  treatmentDelayStrategy: SchedulingDelayStrategy;
  scope: ConciergeJobScope;
}

export interface ConciergeReschedulingInput {
  fromDate: string;
  toDate: string;
  plan: string;
  plans: ConciergeReschedulingInputData[];
}

export enum ConciergeJobKind {
  Reschedule = 'reschedule',
}

export enum ConciergeJobScope {
  Full = 'full',
  PlanningOnly = 'planning_only',
  TreatmentOnly = 'treatment_only',
}

export interface ConciergeJob {
  id: string;
  status: SchedulingJobStatus;
  kind: ConciergeJobKind;
  savedModifications: Appointment[];
  dateCreated: string;
  userCreated: SimpleUser;
}

export interface ConciergeRescheduleResult {
  movedAppointments: Appointment[];
  newAppointments: ROAppointmentResult[];
  rescheduledPatients?: RORescheduledPatient[];
}

export interface ConciergeRescheduleJob extends ConciergeJob {
  kind: ConciergeJobKind.Reschedule;
  plan: ROPatientPlan['id'];
  data: ConciergeReschedulingInput;
  results: { patientAppointments: ROScheduleResult };
}

export interface ConciergeJobList
  extends Omit<ConciergeRescheduleJob, 'kind' | 'savedModifications'> {}
